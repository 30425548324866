import React from "react";
import { graphql } from "gatsby";
import * as _ from "./config/fragments";

import cx from "classnames";
import theme from "../constants/theme";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { Wrapper } from "../components/core";
import { SubHeader, AboutSubHeader, CTASection } from "../components/blocks";

export default function PartnersPage(props) {
  const siteMetaData = props.data.site.siteMetadata;
  const siteData = props.data.allSanitySiteConfig.edges[0].node;
  const sharedData = props.data.allSanitySharedSections.edges[0].node;
  const pageData = props.data.allSanityPartnersPage.edges[0].node;

  return (
    <Layout
      locale={props.pageContext.locale}
      siteMetaData={siteMetaData}
      mainNav={siteData.mainNav}
      alternateNav={siteData.alternateNav}
      contact={sharedData.contact}>
      <SEO title={pageData.pageTitle?.localized} />
      <AboutSubHeader
        title={pageData.header?.title?.localized}
        description={pageData.header?.description?.localized}
        coverImageUrl={pageData.header?.coverImage?.asset?.url}
        videoLink={pageData.header?.videoLink}
        showVideo={pageData.header?.showVideo}
      />
      {partnersSection()}
      <CTASection
        title={sharedData.callToActionSection?.title?.localized}
        callToAction={sharedData.callToActionSection?.callToAction}
      />
    </Layout>
  );

  function partnersSection() {
    const partnerSections = pageData.partnerSections ?? [];
    if (partnerSections.length === 0) return null;
    return (
      <div className={cx("bg-bg2", theme.spacing.sectionY_md)}>
        <Wrapper>{partnerSectionsGrid(partnerSections)}</Wrapper>
      </div>
    );
  }

  function partnerSectionsGrid(sections = []) {
    return (
      <div className="grid grid-cols-1 gap-30 md:gap-60 w-full">
        {sections.map(section => {
          const sectionTitle = section.sectionTitle?.localized ?? "Partners";
          const partners = section.partners ?? [];
          return partnersGrid(sectionTitle, partners);
        })}
      </div>
    );
  }

  function partnersGrid(sectionTitle = "", items = []) {
    return (
      <div className="flex flex-col justify-center items-center w-full" key={sectionTitle}>
        <div className="md:w-6/12">
          <h3 className="font-medium text-md2 text-text1 text-center">{sectionTitle}</h3>
        </div>
        <div className="h-20 lg:h-30 bg-transparent" />
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-10 md:gap-20 w-full">
          {items.map(item => {
            const company = item.company ?? "Company";
            const subTitle = item.subTitle;
            const websiteUrl = item.website;
            const logoUrl = item.logo?.asset?.url;
            return partnerCard(company, subTitle, websiteUrl, logoUrl);
          })}
        </div>
      </div>
    );
  }

  function partnerCard(company = "", subTitle = "", websiteUrl, logoUrl) {
    return (
      <a
        key={company}
        href={websiteUrl}
        target="_blank"
        className="flex flex-col justify-between h-240 rounded-lg shadow-sm border-solid border-one border-gray-200 hover:border-accent2 bg-bg1">
        <div className="flex justify-center items-center h-full p-20 mt-30">
          <div className="h-80">
            <img className="w-auto h-full" src={logoUrl} alt={company} />
          </div>
        </div>
        <div className="px-12 py-10">
          <h3 className="font-medium text-md4 text-text1 pb-4">{company}</h3>
          <p className="text-text3">{subTitle}</p>
        </div>
      </a>
    );
  }
}

export const query = graphql`
  query ($locale: String) {
    # Site Meta Data
    site {
      ...SiteMetaData
    }
    # Site
    allSanitySiteConfig {
      edges {
        node {
          ...MainNav
          ...AlternateNav
        }
      }
    }
    # Shared
    allSanitySharedSections {
      edges {
        node {
          ...SharedSections
        }
      }
    }
    # Page
    allSanityPartnersPage {
      edges {
        node {
          pageTitle {
            localized(locale: $locale)
          }
          header {
            title {
              localized(locale: $locale)
            }
            description {
              localized(locale: $locale)
            }
            coverImage {
              ...Image
            }
            videoLink
            showVideo
          }
          partnerSections {
            sectionTitle {
              localized(locale: $locale)
            }
            partners {
              company
              subTitle
              website
              logo {
                ...Image
              }
            }
          }
        }
      }
    }
  }
`;
